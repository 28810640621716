$mainPadding: 20px;

@mixin flex {
  display: flex;
}

@mixin flex-c-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-a-c {
  align-items: center;
}

@mixin flex-j-c {
  justify-content: center;
}

@mixin flex-j-s-b {
  justify-content: space-between;
}

@mixin flex-a-s-b {
  align-content: space-between;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #eee;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background-color: #a8a8a8;
    }

    &:active {
      background-color: #787878;
    }
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #eee;
  }
}

.mySidebarContent {
  height: 100%;
  .my-layout {
    height: 100%;
  }
  .title {
    @include flex;
    align-items: center;
    padding-left: 25px;
    height: 40px;
    color: #fff;
    font-size: 16px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  .ant-layout-sider {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  .ant-menu {
    background-color: transparent;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  .ant-menu-item {
    line-height: 16px;
  }
  .ant-menu-inline {
    border: none;
  }

  .ant-menu-title-content {
    color: #313131;
    font-size: 14px !important;
  }

  .ant-menu-title-content span {
    color: #999999;
    font-size: 14px !important;
  }

  .ant-menu-submenu-title i {
    color: #313131;
    font-size: 14px !important;
  }

  .anticon-menu-fold {
    font-size: 16px;
  }

  // .ant-menu-sub.ant-menu-inline {
  //   background-color: transparent;
  // }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    color: #006ab2;
  }

  .ant-menu-item span:hover {
    color: #006ab2;
  }
  .ant-menu-item-selected {
    color: #006ab2;
  }

  // .ant-menu-item-active {
  //   background: rgba(255, 255, 255, 0.4) !important;
  //   color: #fff !important;
  // }
  .highlighted {
    font-weight: bold;
    color: red;
  }
}
