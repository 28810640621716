$mainPadding: 20px;

@mixin flex {
  display: flex;
}

@mixin flex-c-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-a-c {
  align-items: center;
}

@mixin flex-j-c {
  justify-content: center;
}

@mixin flex-j-s-b {
  justify-content: space-between;
}

@mixin flex-a-s-b {
  align-content: space-between;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #eee;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background-color: #a8a8a8;
    }

    &:active {
      background-color: #787878;
    }
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #eee;
  }
}

.cardItem {
  .ant-checkbox-disabled {
    background-color: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
  }

  .ant-tag {
    padding: 0 4px;
    height: 22px;
    border-radius: 14px;
    text-align: center;
    background-color: #fff;
    &:nth-child(4n + 1) {
      color: #61d4ad;
      border-color: #61d4ad;
    }
    &:nth-child(4n + 2) {
      color: #aad461;
      border-color: #aad461;
    }
    &:nth-child(4n + 3) {
      color: #fec054;
      border-color: #fec054;
    }
    &:nth-child(4n) {
      color: #54b7fe;
      border-color: #54b7fe;
    }
  }
}
