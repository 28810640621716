$mainPadding: 20px;

@mixin flex {
  display: flex;
}

@mixin flex-c-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-a-c {
  align-items: center;
}

@mixin flex-j-c {
  justify-content: center;
}

@mixin flex-j-s-b {
  justify-content: space-between;
}

@mixin flex-a-s-b {
  align-content: space-between;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #eee;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background-color: #a8a8a8;
    }

    &:active {
      background-color: #787878;
    }
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #eee;
  }
}

.noticeDrawer {
  .ant-drawer-content {
    border-radius: 10px 0px 0px 10px;
  }

  .ant-drawer-body {
    padding-bottom: 5px;
    padding-top: 10px;
  }

  .typeSreach {
    font-size: 14px;
    font-weight: 400;
    color: #0068b2;
    cursor: pointer;
  }

  .cardBox {
    .cardItem {
      min-height: 50px;
      border-bottom: 1px dashed #d8d8d8;
      padding: 10px 0;
      @include flex;
      &:last-of-type {
        border-bottom: none;
      }
      .top {
        .active {
          color: #0068b2;
          margin-left: 10px;
        }
      }

      .bottom {
        .valueItem {
          width: 50%;
          margin-right: 5px;
          @include flex;
          font-size: 12px;
          .label {
            margin-right: 5px;
            color: #9e9e9e;
          }

          .value {
            color: #535353;
          }
        }
      }
    }
  }
}
