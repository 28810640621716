$mainPadding: 20px;

@mixin flex {
  display: flex;
}

@mixin flex-c-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-a-c {
  align-items: center;
}

@mixin flex-j-c {
  justify-content: center;
}

@mixin flex-j-s-b {
  justify-content: space-between;
}

@mixin flex-a-s-b {
  align-content: space-between;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #eee;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background-color: #a8a8a8;
    }

    &:active {
      background-color: #787878;
    }
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #eee;
  }
}

.ModelCheckContent {
  padding: 10px 20px;
  background: #ffffff;
  border-radius: 10px 10px 10px 10px;
  width: 100%;
  height: 100%;

  .myTabs {
    .noReleseInfo {
      .promptValue {
        margin-left: 12px;
        margin-bottom: 16px;
        color: #006ab2;
        font-size: 12px;
      }

      .info {
        @include flex;
        @include flex-j-s-b;
        .selectInfo {
          @include flex;
          @include flex-a-c;
          flex-wrap: nowrap;
          margin-left: 12px;

          .label {
            margin-top: 5px;
            margin-right: 8px;
            text-align: justify;
            text-align-last: justify;
          }
        }

        .btnBox {
          @include flex;
          @include flex-a-c;

          .Icon {
            display: inline-block;
            vertical-align: -10%;
            margin-right: 6px;
            width: 10px;
            height: 10px;
          }

          .ant-btn {
            vertical-align: middle;
            margin-left: 10px;
            font-size: 12px !important;
          }
        }
      }

      .infoBox {
        margin-top: 10px;
        padding-left: 10px;
        width: 100%;
        height: 100%;
      }
    }

    .ReleseInfo {
      margin-top: 10px;

      .promptValue {
        @include flex;
        @include flex-j-s-b;
        margin-left: 12px;
        height: 32px;
        line-height: 32px;
        color: #313131;
        font-size: 14px;

        .selectInfo {
          @include flex;
          @include flex-a-c;

          .label {
            margin-right: 8px;
            text-align: justify;
            text-align-last: justify;
          }
        }

        .detailItem {
          @include flex;
          @include flex-a-c;
          margin-top: 3px;
          justify-content: flex-end;
          font-size: 14px;

          .detailKey {
            margin-right: 8px;
          }

          .detailValue {
            margin-right: 16px;
            color: #006ab2;

            &:nth-last-child(1) {
              margin-right: 0;
            }
          }
        }
      }

      .infoBox {
        margin-bottom: 10px;
        padding-left: 10px;
        width: 100%;
        height: 100%;
      }
    }
  }

  .ant-select-arrow {
    display: inline-block;
    vertical-align: -10%;
  }
  .ant-spin {
    height: 730px !important;
    background-color: #fff;
  }
}
