$mainPadding: 20px;

@mixin flex {
  display: flex;
}

@mixin flex-c-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-a-c {
  align-items: center;
}

@mixin flex-j-c {
  justify-content: center;
}

@mixin flex-j-s-b {
  justify-content: space-between;
}

@mixin flex-a-s-b {
  align-content: space-between;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #eee;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background-color: #a8a8a8;
    }

    &:active {
      background-color: #787878;
    }
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #eee;
  }
}

.metaDialogDrawer {
  z-index: 100;

  .ant-drawer-content {
    border-radius: 10px 0px 0px 10px;
  }

  .ant-drawer-body {
    padding: 0px;
  }

  .dialogDrawerForm {
    width: 100%;
    padding: 20px 32px;
    @include flex;
    @include flex-j-s-b;
    flex-wrap: wrap;
    padding-bottom: 0;

    .headerTitleContent {
      width: 100%;
      font-size: 14px;
      margin-bottom: 8px;
      margin-left: -13px;
    }
    .ant-form-item-control-input {
      min-height: 0 !important;
    }

    .btn {
      @include flex;
      align-items: center;
      justify-content: center;
      background-color: #006ab2;
      color: #fff;
      margin-top: -5px;
      font-size: 12px;
    }

    .selectBtn {
      width: 114px;
      margin-right: 240px;
    }

    .checkBtn {
      width: 72px;
    }

    .ant-space {
      @include flex;
      margin-top: -18px;
      .ant-form-item {
        width: 510px !important;
        font-size: 14px;
      }
      .ant-form-item-control-input-content {
        @include flex;
        align-items: center;
        flex-wrap: nowrap;
      }
    }
  }

  // 标签区域
  .site-tag-plus {
    background: #fff;
    width: 64px;
    height: 22px;
    text-align: center;
    color: #61d4ad;
    border-color: #61d4ad;
    border-radius: 14px;
  }

  .edit-tag {
    position: relative;
    user-select: none;
    width: 64px;
    height: 22px;
    margin-bottom: 10px;
    border-radius: 14px;
    text-align: center;
    background-color: #fff;

    &:nth-child(4n + 1) {
      color: #61d4ad;
      border-color: #61d4ad;
    }

    &:nth-child(4n + 2) {
      color: #aad461;
      border-color: #aad461;
    }

    &:nth-child(4n + 3) {
      color: #fec054;
      border-color: #fec054;
    }

    &:nth-child(4n) {
      color: #54b7fe;
      border-color: #54b7fe;
    }
  }

  .tag-input {
    width: 80px;
    margin-right: 8px;
    vertical-align: top;
  }

  .ant-tag-close-icon {
    position: absolute;
    top: -10px;
    right: 2px;
  }
}
