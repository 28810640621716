$mainPadding: 20px;

@mixin flex {
  display: flex;
}

@mixin flex-c-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-a-c {
  align-items: center;
}

@mixin flex-j-c {
  justify-content: center;
}

@mixin flex-j-s-b {
  justify-content: space-between;
}

@mixin flex-a-s-b {
  align-content: space-between;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #eee;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background-color: #a8a8a8;
    }

    &:active {
      background-color: #787878;
    }
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #eee;
  }
}

.VerifyModal {
  .rejectInfo {
    padding: 0 24px;
  }
  .ant-modal-content {
    border-radius: 10px;

    .ant-modal-header {
      padding: 10px 15px;
      border-radius: 10px 10px 0 0;

      .ant-modal-title {
        font-size: 16px;
        font-weight: 400;
        color: #313131;
      }
    }

    .ant-modal-close-x {
      width: 43px;
      height: 43px;
      line-height: 43px;
    }

    .ant-modal-body {
      padding: 20px 0;
    }
  }

  .messageModalContent {
    @include flex-c-c;
    flex-direction: column;
    max-height: 70vh;
    overflow-y: auto;
    @include scrollbar;
    padding: 0 20px;

    .iconBox {
      font-size: 34px;
      color: red;
    }

    .descriptionBox {
      font-size: 16px;
      font-weight: 400;
      color: #535353;
      text-align: center;
    }

    .messageBox {
      font-size: 14px;
      font-weight: normal;
      color: #9e9e9e;
      margin: 10px 0;
      text-align: center;
    }
    .tableContent {
      .ant-table-thead > tr > th {
        padding: 10px;
        text-align: left;
      }
      .ant-table-tbody > tr > td {
        padding: 10px;
      }
      .ant-table-thead
        > tr
        > th:not(:last-child):not(.ant-table-selection-column):not(
          .ant-table-row-expand-icon-cell
        ):not([colspan]):before {
        background-color: transparent;
      }

      .ant-table-cell:hover {
        background: rgba(0, 104, 178, 0.05) !important;
        cursor: pointer;
      }

      .ant-table-cell-row-hover {
        background: rgba(0, 104, 178, 0.05) !important;
        cursor: pointer;
      }
    }

    .btnBox {
      @include flex-c-c;
      flex-direction: row;
      margin: 20px 0 10px 0;
      width: 100%;

      .ant-btn {
        margin-left: 0;

        &:first-of-type {
          margin-right: 20px;
        }

        &:hover {
          border-color: #0068b2;
        }
      }
    }
  }
  // 扩大勾选框的点击范围
  .ant-checkbox-input {
    &::after {
      position: absolute;
      content: "";
      top: -10px;
      bottom: -15px;
      left: -10px;
      right: -700px;
    }
  }
}
