$mainPadding: 20px;

@mixin flex {
  display: flex;
}

@mixin flex-c-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-a-c {
  align-items: center;
}

@mixin flex-j-c {
  justify-content: center;
}

@mixin flex-j-s-b {
  justify-content: space-between;
}

@mixin flex-a-s-b {
  align-content: space-between;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #eee;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background-color: #a8a8a8;
    }

    &:active {
      background-color: #787878;
    }
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #eee;
  }
}

.headerContainer {
  .headerLeft {
    margin-left: 15px;
    @include flex;
    align-items: center;
    .logo {
      height: 60px;
      background-size: 50% 32px;
    }

    .logo img {
      width: 170px;
      height: 60px;
    }
    .left-title {
      font-size: 16px;
      color: #313131;
      font-weight: 500;
      @include flex-c-c;
    }
    .left-value {
      .ant-select-selector {
        border-radius: 8px;
      }
    }
  }

  .ant-layout-header {
    padding: 0;
    height: 48px;
    line-height: 48px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .ant-header-right {
    float: right;
    margin-right: 23px;
  }

  .ant-header-right a {
    width: 28px;
    height: 16px;
    font-size: 14px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    color: #068abd;
    line-height: 16px;
    margin-right: 40px;
    margin-left: 15px;
  }

  .ant-header-right .sys-pepo {
    width: 70px;
    height: 16px;
    font-size: 14px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    color: #535353;
    line-height: 16px;
    margin: 0px 8px;
  }

  .ant-header-right .setting {
    margin: 0px 16px;
  }

  .ant-header-right img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  .ant-header-right .anticon {
    font-size: 18px;
  }
}

.headerTipContent {
  max-width: none;

  .headerTip {
    .headerTipTitle {
      font-weight: 500;
      font-size: 14px;
    }

    .headerTipText {
      font-size: 12px;
      font-weight: 0;
    }
  }
}
