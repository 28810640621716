$mainPadding: 20px;

@mixin flex {
  display: flex;
}

@mixin flex-c-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-a-c {
  align-items: center;
}

@mixin flex-j-c {
  justify-content: center;
}

@mixin flex-j-s-b {
  justify-content: space-between;
}

@mixin flex-a-s-b {
  align-content: space-between;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #eee;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background-color: #a8a8a8;
    }

    &:active {
      background-color: #787878;
    }
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #eee;
  }
}

.AssetManagementDrawer {
  background-color: #fff;

  .selectBtn {
    border-radius: 38px;
    background-color: #d4d4d4;

    .ant-btn {
      border-radius: 38px;
      color: #fff;
      font-size: 12px !important;
    }
  }

  .dialogDrawerForm {
    width: 100%;
    padding: 20px 32px;
    @include flex;
    flex-wrap: wrap;
    padding-bottom: 0;

    .headerTitleContent {
      width: 100%;
      font-size: 14px;
      margin-bottom: 8px;
      margin-left: -13px;
    }

    .ant-form-item {
      margin-right: 56px;
    }

    .drawerFooterBtnBox {
      width: 100%;
    }
  }

  .drawerFooterBtnBox {
    margin-bottom: 10px;
  }
}
