$mainPadding: 20px;

@mixin flex {
  display: flex;
}

@mixin flex-c-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-a-c {
  align-items: center;
}

@mixin flex-j-c {
  justify-content: center;
}

@mixin flex-j-s-b {
  justify-content: space-between;
}

@mixin flex-a-s-b {
  align-content: space-between;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #eee;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background-color: #a8a8a8;
    }

    &:active {
      background-color: #787878;
    }
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #eee;
  }
}

.DesignStandardContent {
  padding: 10px 20px;
  background: #ffffff;
  border-radius: 10px 10px 10px 10px;
  width: 100%;
  height: 100%;

  .dialogDrawerForm {
    width: 100%;
    padding: 15px 15px;
    @include flex;
    flex-wrap: wrap;

    .headerTitleContent {
      width: 100%;
      font-size: 14px;
      margin-bottom: 8px;
      margin-left: -13px;
    }

    .ant-form-item {
      margin-right: 56px;
    }

    .prefixValue {
      @include flex;
      align-items: center;
      width: 100%;
      margin: 30px 0 16px 25px;
      .PlusCircleFilled {
        font-size: 18px;
        margin-left: 60px;
      }
    }

    .ant-space {
      width: 100%;
      margin-left: 25px;
    }
  }

  .FooterBtnBox {
    @include flex;
    position: absolute;
    bottom: 24px;
    right: 8px;

    .ant-btn {
      margin-right: 24px;
      background-color: #016db4;
      border-color: #016db4;
      color: #fff;
      font-size: 14px !important;
    }
  }
}
