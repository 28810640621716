$mainPadding: 20px;

@mixin flex {
  display: flex;
}

@mixin flex-c-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-a-c {
  align-items: center;
}

@mixin flex-j-c {
  justify-content: center;
}

@mixin flex-j-s-b {
  justify-content: space-between;
}

@mixin flex-a-s-b {
  align-content: space-between;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #eee;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background-color: #a8a8a8;
    }

    &:active {
      background-color: #787878;
    }
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #eee;
  }
}

.ModelApplyContent {
  width: 100%;
  height: 100%;
  @include flex;
  flex-direction: column;
  align-items: flex-end;

  .topBox {
    width: 100%;
    height: 130px;
    background: #ffffff;
    border-radius: 10px;
    padding: 18px;
    @include flex;
    flex-direction: column;

    .searchItem {
      @include flex;
      align-items: center;
      height: 29px;
      font-size: 14px;
      margin-bottom: 10px;

      .label {
        width: 80px;
        margin-right: 20px;
        font-weight: normal;
        color: #999999;
        display: inline-block;
        text-align-last: right;
      }

      .optionsBox {
        @include flex;

        .subvalue {
          @include flex;
          align-items: center;
          color: #006ab2;
        }

        .optionItem {
          height: 24px;
          line-height: 24px;
          padding: 0 7px;
          margin-right: 12px;
          text-align: center;
          cursor: pointer;
        }

        .borderBottom {
          width: 200px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.08);
          padding: 0px;
        }
      }
    }

    .filterMore {
      margin-left: 14px;
      @include flex;
      align-items: center;
      justify-content: end;

      .ant-btn {
        height: 28px;
        vertical-align: middle;
        margin-left: 10px;
        font-size: 12px !important;
      }
    }
  }

  .filterItem {
    width: 98.4%;
    margin-left: 24px;
    height: 46px;
    line-height: 40px;
    font-size: 14px;
    @include flex;
    user-select: none;

    .ant-checkbox + span {
      font-size: 14px;
      color: #535353;
      padding-right: 32px;
    }

    .optionItem {
      @include flex;
      align-items: center;
      margin-right: 30px;
      color: #535353;
      font-size: 14px;
      font-weight: normal;

      .iconBox {
        @include flex;
        margin-left: 5px;
        flex-direction: column;
        color: #787878;

        .downIcon {
          margin-top: -5px;
        }
      }
    }
  }

  .cardBox {
    width: 100%;
    @include flex;
    flex-wrap: wrap;
    height: 74%;
    overflow-y: auto;
    margin-bottom: 10px;

    .noData {
      width: 100%;
      height: 660px;
      @include flex-c-c;
    }

    .ant-spin {
      margin-top: 310px;
    }

    .cardItem {
      .ant-checkbox-input {
        &::after {
          position: absolute;
          content: "";
          top: -10px;
          bottom: -35px;
          left: -20px;
          right: -1100px;
        }
      }
    }
  }

  .paginationBox {
    height: 30px;
  }

  .ant-checkbox-wrapper {
    margin-bottom: 0;
  }
}
