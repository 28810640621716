$mainPadding: 20px;

@mixin flex {
  display: flex;
}

@mixin flex-c-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-a-c {
  align-items: center;
}

@mixin flex-j-c {
  justify-content: center;
}

@mixin flex-j-s-b {
  justify-content: space-between;
}

@mixin flex-a-s-b {
  align-content: space-between;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #eee;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background-color: #a8a8a8;
    }

    &:active {
      background-color: #787878;
    }
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #eee;
  }
}

.MgtStatistics {
  width: 100%;
  height: 100%;

  .topValue {
    height: 56px;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px 24px;
    font-size: 12px;
    color: #313131;
    .selectInfo {
      @include flex;
      @include flex-a-c;
      flex-wrap: nowrap;

      .label {
        margin-top: 5px;
        margin-right: 8px;
        text-align: justify;
        text-align-last: justify;
      }
    }
    .ant-select {
      margin-right: 20px;
    }
  }
  .HeaderTitle {
    margin: 12px 0;
    .Icon {
      display: inline-block;
      vertical-align: -10%;
      margin-right: 8px;
      width: 10px;
      height: 10px;
    }
    .ant-btn {
      height: 30px;
      vertical-align: middle;
      margin-left: 10px;
      font-size: 12px !important;
    }
  }
  .guidanceValue {
    // height: 260px;
    height: calc(25vh);
    background-color: #fff;
    border-radius: 10px;
    padding: 10px 24px;
  }
  .tableValue {
    position: relative;
    height: 50%;
    background-color: #fff;
    border-radius: 10px;
    // padding: 10px 24px;
  }
}
