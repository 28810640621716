$mainPadding: 20px;

@mixin flex {
  display: flex;
}

@mixin flex-c-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-a-c {
  align-items: center;
}

@mixin flex-j-c {
  justify-content: center;
}

@mixin flex-j-s-b {
  justify-content: space-between;
}

@mixin flex-a-s-b {
  align-content: space-between;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #eee;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background-color: #a8a8a8;
    }

    &:active {
      background-color: #787878;
    }
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #eee;
  }
}

$headerTitleHeight: 26px;
.headerTitleContent {
  height: $headerTitleHeight;
  @include flex;
  @include flex-a-c;
  @include flex-j-s-b;
  .left {
    @include flex-c-c;
    height: $headerTitleHeight;
    .iconBox {
      height: $headerTitleHeight;
      width: $headerTitleHeight;
      // background: #006BB3;
      @include flex;
      @include flex-c-c;
      margin-right: 10px;
      font-size: 16px;
      color: #fff;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .block {
      width: 2px;
      height: 16px;
      background: linear-gradient(223deg, #006bb3 0%, #15d2d6 100%);
      margin-right: 10px;
    }

    .title {
      font-size: 16px;
      font-weight: 600;
    }
  }
  .right {
    height: $headerTitleHeight;
    @include flex-c-c;
  }
}
