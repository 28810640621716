$mainPadding: 20px;

@mixin flex {
  display: flex;
}

@mixin flex-c-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-a-c {
  align-items: center;
}

@mixin flex-j-c {
  justify-content: center;
}

@mixin flex-j-s-b {
  justify-content: space-between;
}

@mixin flex-a-s-b {
  align-content: space-between;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #eee;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background-color: #a8a8a8;
    }

    &:active {
      background-color: #787878;
    }
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #eee;
  }
}

.ServiceDevDrawer {
  z-index: 100;

  .ant-drawer-content {
    border-radius: 10px 0px 0px 10px;
  }

  .ant-drawer-body {
    padding: 0px;
  }

  .dialogDrawerForm {
    width: 100%;
    padding: 20px 32px;
    @include flex;
    @include flex-j-s-b;
    flex-wrap: wrap;
    padding-bottom: 0;

    .headerTitleContent {
      width: 100%;
      font-size: 14px;
      margin-bottom: 8px;
      margin-left: -13px;
    }
    .ant-form-item-control-input {
      min-height: 0 !important;
    }

    .btn {
      @include flex;
      align-items: center;
      justify-content: center;
      background-color: #006ab2;
      color: #fff;
      margin-top: -5px;
      font-size: 12px;
    }

    .selectBtn {
      width: 114px;
      margin-right: 240px;
    }

    .checkBtn {
      width: 72px;
    }

    .ant-space {
      @include flex;
      margin-top: -18px;
      .ant-form-item {
        width: 510px !important;
        font-size: 14px;
      }
      .ant-form-item-control-input-content {
        @include flex;
        align-items: center;
        flex-wrap: nowrap;
      }
    }
  }

  .tableInfo {
    width: 100%;
    @include flex;
    align-items: center;
    margin-left: 10px;
    margin-bottom: 12px;
    .tableIcon {
      margin-right: 10px;
      margin-top: 7px;
    }
    .typeValue {
      width: 50px;
      text-align: center;
      font-size: 12px;
      color: #fff;
    }
    .nameValue {
      width: 420px;
      cursor: pointer;
      font-size: 14px;
      margin-right: 30px;
      &:hover {
        color: #006ab2;
      }
    }
  }
}
