$mainPadding: 20px;

@mixin flex {
  display: flex;
}

@mixin flex-c-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-a-c {
  align-items: center;
}

@mixin flex-j-c {
  justify-content: center;
}

@mixin flex-j-s-b {
  justify-content: space-between;
}

@mixin flex-a-s-b {
  align-content: space-between;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #eee;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background-color: #a8a8a8;
    }

    &:active {
      background-color: #787878;
    }
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #eee;
  }
}

.BatchDrawer {
  z-index: 100;

  .ant-drawer-content {
    border-radius: 10px 0px 0px 10px;
  }

  .ant-drawer-body {
    padding: 0px;
  }

  .dialogDrawerForm {
    width: 100%;
    padding: 20px 32px;
    @include flex;
    @include flex-j-s-b;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 0;

    .headerTitleContent {
      margin-bottom: 8px;
      margin-left: -13px;
    }

    // 下载
    .downLoadValue {
      color: #006ab2;
      font-size: 12px;

      span:nth-child(1) {
        margin-right: 8px;
      }
    }

    .draggerBox {
      width: 540px;
      height: 240px;
      margin-bottom: 8px;
    }
    .uploadBox {
      width: 100%;
      height: 100%;
      @include flex-c-c;
      flex-direction: column;

      .anticon {
        font-size: 70px;
        color: #d8d8d8;
      }

      .text {
        font-size: 14px;
        font-weight: normal;
        color: #9e9e9e;
        padding: 0 3px;

        span {
          color: #0068b2;
        }
      }

      .fileTip {
        font-size: 12px;
        font-family: PingFang SC-常规, PingFang SC;
        font-weight: normal;
        color: #9e9e9e;
        margin: 8px 0;
      }
    }

    .fileLineItem {
      @include flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5px;
      cursor: pointer;
    }
    .fileItem {
      @include flex-c-c;
      .ant-typography {
        margin-left: 5px;
        margin-bottom: 0;
      }
    }
    .closeIcon:hover {
      color: #0068b2;
    }
  }

  .ant-select {
    .ant-tag {
      height: 30px;
      margin-right: 8px;
      padding: 0 10px;
      text-align: center;
      line-height: 30px;
      font-size: 12px;
      border-radius: 4px;
      background-color: #f7f7f7;
      border: 1px solid #f7f7f7;
    }

    .ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
      color: #000;
    }
  }
}
