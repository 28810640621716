$mainPadding: 20px;

@mixin flex {
  display: flex;
}

@mixin flex-c-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-a-c {
  align-items: center;
}

@mixin flex-j-c {
  justify-content: center;
}

@mixin flex-j-s-b {
  justify-content: space-between;
}

@mixin flex-a-s-b {
  align-content: space-between;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #eee;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background-color: #a8a8a8;
    }

    &:active {
      background-color: #787878;
    }
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #eee;
  }
}

.ReleaseDrawer {
  z-index: 100;

  .ant-drawer-content {
    border-radius: 10px 0px 0px 10px;
  }

  .ant-drawer-body {
    padding: 20px;
  }

  .dialogDrawerForm {
    width: 100%;
    padding: $mainPadding;
    @include flex;
    @include flex-j-s-b;
    flex-wrap: wrap;
    padding-bottom: 0;
  }

  .ant-form-item-label {
    width: 100%;

    label {
      width: 100%;
    }
  }

  .releaseContent span {
    color: #fe545f;
  }
  .elementUi {
    margin-top: -10px;
    .eleUl {
      @include flex;
      flex-direction: column;
      margin-left: 20px;
      list-style-position: inside; /* 将圆点放在内容内部 */
    }
    .eleLi {
      font-size: 14px;
      align-items: center; /* 将内容垂直居中对齐 */
    }
  }

  .tableInfo {
    width: 100%;
    @include flex;
    align-items: center;
    margin-bottom: 12px;
    .tableIcon {
      margin-right: 10px;
      margin-top: 7px;
    }
    .typeValue {
      width: 50px;
      text-align: center;
      font-size: 12px;
      background-color: #fec054;
      color: #fff;
    }
    .nameValue {
      cursor: pointer;
      width: 420px;
      font-size: 14px;
      margin-right: 30px;
      &:hover {
        color: #006ab2;
      }
    }
  }
}
