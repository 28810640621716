$mainPadding: 20px;

@mixin flex {
  display: flex;
}

@mixin flex-c-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-a-c {
  align-items: center;
}

@mixin flex-j-c {
  justify-content: center;
}

@mixin flex-j-s-b {
  justify-content: space-between;
}

@mixin flex-a-s-b {
  align-content: space-between;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #eee;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background-color: #a8a8a8;
    }

    &:active {
      background-color: #787878;
    }
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #eee;
  }
}

.VisualBoxContent {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .tooltip {
    @include flex;
    flex-wrap: wrap;
    .group {
      margin-right: 25px;
      @include flex;
      align-items: center;
      .shape {
        margin-right: 4px;
        width: 15px;
        height: 15px;
        border-radius: 50%;
      }
      .name {
        font-size: 12px;
      }
    }
  }

  .g6-component-tooltip {
    background-color: rgba(0, 0, 0, 0.65);
    padding: 10px;
    box-shadow: rgb(174, 174, 174) 0px 0px 10px;
    width: fit-content;
    color: #fff;
    border-radius: 4px;
  }
}
