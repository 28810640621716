$mainPadding: 20px;

@mixin flex {
  display: flex;
}

@mixin flex-c-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-a-c {
  align-items: center;
}

@mixin flex-j-c {
  justify-content: center;
}

@mixin flex-j-s-b {
  justify-content: space-between;
}

@mixin flex-a-s-b {
  align-content: space-between;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #eee;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background-color: #a8a8a8;
    }

    &:active {
      background-color: #787878;
    }
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #eee;
  }
}

.StatisticalData {
  @include flex;
  justify-content: space-between;
  width: 100%;
  height: 120px;
  border-radius: 10px;
  margin-bottom: 20px;

  .applicationValue {
    position: relative;
    background: linear-gradient(135deg, #7abafd 0%, #a4eeff 100%);
    width: 410px;
    padding: 10px 18px;
    border-radius: 10px;
    .block {
      background: linear-gradient(180deg, #ffffff 0%, #16d8d8 100%);
    }
    .title {
      color: #fff;
    }
    .bottomInfo {
      height: 90px;
      @include flex;
      align-items: center;
      color: #fff;
      margin-left: 15px;
      .applicationInfo {
        font-size: 16px;
        margin-right: 15px;
      }
      .squareInfo {
        position: absolute;
        bottom: -15px;
        right: -12px;
        width: 112px;
        height: 112px;
        @include flex;
        flex-wrap: wrap;
      }
      .squareInfo div {
        width: 45px;
        height: 45px;
        border-radius: 10px;
        margin-right: 10px;
        background: linear-gradient(
          180deg,
          #ffffff 0%,
          rgba(255, 255, 255, 0) 100%
        );
        &:nth-child(2) {
          transform: rotate(-45deg);
        }
      }
    }
  }
  .statistics {
    width: calc(100% - 430px);
    background: #ffffff;
    padding: 10px 18px;
    border-radius: 10px;

    .headerTitleContent {
      margin-bottom: 25px;
      .left {
        width: 68px;
      }
      .right {
        width: calc(100% - 80px);
        @include flex;
        justify-content: end;
        position: relative;
        .detailInfo {
          font-size: 12px;
          color: #006ab2;
          .detail {
            position: absolute;
            left: 0;
            top: 4px;
          }
        }
      }
    }

    .iconData {
      padding: 0 50px;
      height: 40px;
      @include flex;
      justify-content: space-between;
      .line {
        width: 10px;
        border-right: 1px solid #d8d8d8;
      }

      .iconInfo {
        @include flex;
        align-items: center;
        .icon {
          font-size: 40px;
          color: #006ab2;
        }
        .numberInfo {
          margin-left: 15px;
          font-size: 12px !important;
          color: #535353;
        }
      }
    }
  }
}
