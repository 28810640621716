$mainPadding: 20px;

@mixin flex {
  display: flex;
}

@mixin flex-c-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-a-c {
  align-items: center;
}

@mixin flex-j-c {
  justify-content: center;
}

@mixin flex-j-s-b {
  justify-content: space-between;
}

@mixin flex-a-s-b {
  align-content: space-between;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #eee;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background-color: #a8a8a8;
    }

    &:active {
      background-color: #787878;
    }
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #eee;
  }
}

.sidebarContainer {
  height: 100%;

  .my-layout {
    height: 100%;
  }

  .logo {
    position: relative;
    float: left;
    width: 100%;
    height: 64px;
    background-size: 50% 32px;
  }

  .logo img {
    position: absolute;
    left: 10px;
    top: 5px;
    width: calc((100% - 70px));
    height: calc(100% - 10px);
  }

  .logo .anticon-menu-unfold {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    right: 5%;
    font-size: 18px;
    // color: #fff;
  }

  .logo .anticon-menu-fold {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    // color: #fff;
  }

  .ant-menu {
    background-color: transparent;
  }
  .ant-menu-item {
    line-height: 16px;
  }
  .ant-menu-inline {
    border: none;
  }

  .ant-menu svg {
    fill: #fff;
    font-size: 16px;
    height: 16px;
    width: 16px;
  }

  .ant-menu-title-content {
    color: #fff;
    font-size: 16px;
    // font-weight: 700;
  }
  .ant-menu-submenu-title {
    font-weight: 500;
    padding-left: 5px !important;
  }

  .ant-menu-title-content a {
    color: #fff;
    font-size: 16px;
  }

  .ant-menu-submenu-title i {
    color: #fff;
    font-size: 16px;
  }

  .anticon-menu-fold {
    font-size: 18px;
  }

  .ant-menu-sub.ant-menu-inline {
    background-color: transparent;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: rgba(255, 255, 255, 0.4);
  }

  .ant-menu-item a:hover {
    color: #fff;
  }

  .ant-menu-item-active {
    background: rgba(255, 255, 255, 0.4) !important;
    color: #fff !important;
  }
}
.sidebarContainer .ant-menu-title-content a {
  font-size: 14px;
}
