$mainPadding: 20px;

@mixin flex {
  display: flex;
}

@mixin flex-c-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-a-c {
  align-items: center;
}

@mixin flex-j-c {
  justify-content: center;
}

@mixin flex-j-s-b {
  justify-content: space-between;
}

@mixin flex-a-s-b {
  align-content: space-between;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #eee;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background-color: #a8a8a8;
    }

    &:active {
      background-color: #787878;
    }
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #eee;
  }
}

.DetailTable {
  width: 100%;
  color: "#006AB2";
  margin-bottom: 10px;

  .ant-table-thead {
    border-radius: 10px !important;
  }

  .ant-table-thead > tr > th {
    font-weight: normal;
    font-size: 12px;
    text-align: left !important;
  }

  .ant-table-tbody > tr > td {
    padding: 13px !important;
    font-size: 12px;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan]):before {
    background-color: transparent;
  }

  .detailsBoxNew {
    margin-left: 7px;
    margin-bottom: 8px;
    @include flex;
    width: 100%;
    justify-content: space-between;
    .detailItems {
      @include flex;
      width: 50%;
      .Key {
        margin-right: 10px;
      }
    }
  }
}
