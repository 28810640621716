$mainPadding: 20px;

@mixin flex {
  display: flex;
}

@mixin flex-c-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-a-c {
  align-items: center;
}

@mixin flex-j-c {
  justify-content: center;
}

@mixin flex-j-s-b {
  justify-content: space-between;
}

@mixin flex-a-s-b {
  align-content: space-between;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #eee;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background-color: #a8a8a8;
    }

    &:active {
      background-color: #787878;
    }
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #eee;
  }
}

.Overview {
  width: 100%;
  height: 100%;

  .MySteps {
    width: 100%;
    height: 21%;
    min-height: 180px;
    background: #ffffff;
    border-radius: 10px;
    padding: 10px 18px;
    margin-bottom: 20px;

    .stepsValue {
      width: 80%;
      margin-top: 15px;
      margin-left: 110px;

      .ant-steps-item-title {
        font-size: 12px;
        color: #313131;
        margin-left: -7px;
      }
      .ant-steps-item-subtitle {
        font-size: 12px;
        color: #787878;
        width: 135px;
      }
      .ant-steps-item-description {
        font-size: 14px;
        color: #006ab2;
        cursor: pointer;
      }
      .stepStatus {
        width: 36px;
        height: 36px;
        @include flex-c-c;
        font-size: 16px;
        border-radius: 50%;
        background-color: #1890ff;
        color: #fff;
      }
      .stepStatu {
        width: 36px;
        height: 36px;
        @include flex-c-c;
        font-size: 16px;
        border-radius: 50%;
        color: rgba(0, 0, 0, 0.25);
        border: 1px solid rgba(0, 0, 0, 0.25);
      }
    }
  }

  .cardBox {
    width: 100%;
    height: 72%;
    background: #ffffff;
    border-radius: 10px;
    padding: 10px 18px;
    .applyValue {
      color: #006ab2;
      font-size: 12px;
      cursor: pointer;
    }
  }

  .headerTitleContent .left .title {
    font-size: 14px;
  }
}
