$mainPadding: 20px;

@mixin flex {
  display: flex;
}

@mixin flex-c-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-a-c {
  align-items: center;
}

@mixin flex-j-c {
  justify-content: center;
}

@mixin flex-j-s-b {
  justify-content: space-between;
}

@mixin flex-a-s-b {
  align-content: space-between;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #eee;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background-color: #a8a8a8;
    }

    &:active {
      background-color: #787878;
    }
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #eee;
  }
}

html,
body,
#root {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  min-width: 1550px;
  overflow: hidden;
  overflow-x: auto;
}

/* Input输入框type=number时去掉内部上下箭头 */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.ant-input-textarea-show-count:after {
  font-size: 12px;
  // transform: translate(-8px, -20px);
}

textarea {
  @include scrollbar;
  padding: 4px 6px 4px 11px;
  height: calc(100% - 20px) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-drawer-extra {
  &:hover {
    color: #0068b2;
  }
}

.hoverIcon {
  &:hover {
    color: #0068b2 !important;
  }
}

.iconSvg {
  height: 14px;
  width: 14px;
  margin-right: 5px;
  cursor: pointer;
  color: #313131;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  div {
    height: 14px;
    width: 14px;
    @include flex-c-c;
  }

  svg {
    height: 14px;
    width: 14px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

#nprogress .spinner {
  display: none !important;
}

#root {
  .ant-btn-primary {
    border-color: #0068b2;
    background: #0068b2;
  }

  .ant-btn-primary[disabled] {
    color: #c1c1c1;
  }

  .ant-btn-primary:hover {
    border-color: #0068b2df;
    background: #0068b2df;
  }
}

.myTabs {
  height: 100%;
  width: 100%;

  .ant-tabs-tabpane {
    height: 100%;
  }

  .ant-tabs-nav {
    margin: 0 0 15px 0;
  }

  .ant-tabs-tab:first-of-type {
    margin-left: 13px;
  }

  .ant-tabs-tab {
    padding: 10px 0px 5px 0;
    margin-left: 50px;
  }

  .ant-tabs-tab-btn {
    color: #9e9e9e;
  }

  .ant-tabs-content-holder {
    height: calc(100% - 30px);
  }

  .ant-tabs-content {
    height: 100%;
  }

  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: #006ab2;
    }
  }

  .ant-tabs-ink-bar {
    // width: 20px !important;
    background: #006ab2;
  }
}

.myPagination {
  .ant-pagination-item {
    a:hover {
      color: #0068b2;
      // background: rgba(0, 104, 178, 0.09803921729326248);
    }
  }

  .ant-pagination-item-active {
    a {
      color: #0068b2;
      background: rgba(0, 104, 178, 0.09803921729326248);
    }
  }
}

.drawerFooterBtnBox {
  @include flex;
  @include flex-a-c;
  flex-direction: row-reverse;

  .ant-btn {
    width: 90px;
    margin-left: 10px;
    // color: #fff;
    // background: #016DB4;
    // border-color: #0068B2;
  }
}

.myTable {
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan]):before {
    width: 0;
    height: 0;
  }

  .ant-table-body {
    @include scrollbar;
  }

  .ant-table-container {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    .ant-table-thead {
      .ant-table-cell {
        &:first-of-type {
          border-top-left-radius: 4px;
        }

        &:last-of-type {
          border-top-right-radius: 4px;
        }

        background: #efefef;
        font-size: 14px;
        font-weight: 500;
        color: #535353;
        padding: 8px;
      }

      .ant-table-cell-scrollbar:not([rowspan]) {
        box-shadow: none;
      }
    }

    .ant-table-tbody {
      .ant-table-row {
        .ant-table-cell {
          padding: 8px;
          font-size: 14px;
          font-weight: normal;
          color: #535353;
        }
      }
    }
  }
}

.myFormTable {
  .ant-spin {
    max-height: none !important;
  }

  .myLoadingIcon {
    width: 24px;
    height: 24px;
    margin-bottom: 5px;
  }

  .ant-table-body {
    @include scrollbar;
  }

  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-body
    > table
    > tbody
    > tr
    > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-body
    > table
    > tfoot
    > tr
    > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-body
    > table
    > tfoot
    > tr
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-body
    > table
    > thead
    > tr
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tfoot
    > tr
    > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tfoot
    > tr
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > tbody
    > tr
    > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > tfoot
    > tr
    > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > tfoot
    > tr
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > thead
    > tr
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-summary
    > table
    > tbody
    > tr
    > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-summary
    > table
    > tfoot
    > tr
    > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-summary
    > table
    > tfoot
    > tr
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-summary
    > table
    > thead
    > tr
    > th {
    border-right: 1px solid rgba(0, 104, 178, 0.1);
  }

  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table {
    border-top: 1px solid rgba(0, 104, 178, 0.1);
  }

  .ant-table.ant-table-bordered > .ant-table-container {
    border-left: 1px solid rgba(0, 104, 178, 0.1);
  }

  .ant-table-container {
    border-radius: 10px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(0, 104, 178, 0.1);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;
  }

  .ant-table table {
    border-radius: 10px 10px 0 0;
  }

  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 10px;
  }

  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 10px;
  }

  .ant-table-tbody tr:last-of-type td {
    border-bottom: none;
  }

  .ant-table-tbody tr:last-of-type td:first-child {
    border-bottom-left-radius: 10px;
  }

  .ant-table-tbody tr:last-of-type td:last-child {
    border-bottom-right-radius: 10px;
  }

  .ant-table-thead > tr > th {
    text-align: center;
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #535353;
    background: rgba(0, 104, 178, 0.05);
    border-bottom: 1px solid rgba(0, 104, 178, 0.1);
  }

  .ant-table-tbody > tr > td {
    font-size: 12px;
    font-family: PingFang SC-中等, PingFang SC;
    font-weight: normal;
    color: #535353;
    border-bottom: 1px solid rgba(0, 104, 178, 0.1);
  }

  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    padding: 16px;
  }

  .noPadding {
    padding: 0px !important;
  }

  .ant-table-cell:hover {
    background: rgba(0, 104, 178, 0.05) !important;
    cursor: pointer;
    // user-select: none;
  }

  .ant-table-cell-row-hover {
    background: rgba(0, 104, 178, 0.05) !important;
    cursor: pointer;
    // user-select: none;
  }

  .ant-typography p,
  div.ant-typography {
    margin-bottom: 0;
  }
}

.myDescriptions {
  .ant-descriptions-view,
  .ant-descriptions-item-content,
  .ant-descriptions-item-label,
  .ant-descriptions-row {
    border-color: rgba(0, 104, 178, 0.1);
  }

  .ant-descriptions-view {
    border-radius: 4px;
  }

  .ant-descriptions-row {
    display: flex;
  }

  .ant-descriptions-item-content {
    color: #535353;
    flex: 1;
    display: block;
  }

  .ant-descriptions-item-label {
    background: rgba(0, 104, 178, 0.05);
    text-align: center;
    color: #535353;
    width: 170px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.myCardPopover {
  padding-top: 5px;
  padding-left: 5px;
  width: 300px;
  max-height: 400px;

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    border-radius: 4px;
    box-shadow: 0px 3px 8px 0px rgb(0 0 0 / 16%);
  }
}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: #ffffff;
    border-color: #0068b2 !important;

    &:after {
      border: 1px solid #0068b2;
      border-top: 0;
      border-left: 0;
    }
  }

  &:after {
    border-color: #0068b2;
    color: #0068b2;
  }

  & + span {
    color: #0068b2;
    font-weight: 500;
  }
}

.ant-checkbox:hover {
  .ant-checkbox-inner {
    border-color: #0068b2;
  }
}

.ant-checkbox-wrapper {
  // margin-bottom: 5px;

  &:hover {
    .ant-checkbox-inner {
      border-color: #0068b2;
    }
  }
}

.ant-checkbox-indeterminate {
  .ant-checkbox-inner {
    // border-color:#0068B2;
  }

  .ant-checkbox-inner:after {
    height: 2px;
    background-color: #0068b2;
  }
}

.ant-btn {
  border-radius: 4px;
}

.myModal {
  .ant-modal-content {
    border-radius: 10px;

    .ant-modal-header {
      padding: 10px 15px;
      border-radius: 10px 10px 0 0;

      .ant-modal-title {
        font-size: 16px;
        font-weight: 400;
        color: #313131;
      }
    }

    .ant-modal-close-x {
      width: 43px;
      height: 43px;
      line-height: 43px;
    }

    .ant-modal-body {
      padding: 20px;
    }
  }

  .messageModalContent {
    @include flex-c-c;
    flex-direction: column;
  }
}

.myRadio {
  .ant-radio-checked:after {
    border: 1px solid #0068b2;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: #0068b2;
  }

  .ant-radio-inner:after {
    background-color: #0068b2;
  }

  .ant-radio.ant-radio-disabled .ant-radio-inner {
    border-color: #d9d9d9 !important;
  }

  .ant-radio-disabled .ant-radio-inner:after {
    background-color: rgba(0, 0, 0, 0.4) !important;
  }
}

.downloadModal {
  .ant-modal-content {
    border-radius: 10px;

    .ant-modal-close {
      right: 5px;
      top: 5px;

      .ant-modal-close-x {
        width: 30px;
        height: 30px;
        line-height: 30px;
      }
    }
  }

  .modalBtnBox {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row-reverse;

    .ant-btn-primary {
      border-color: #0068b2;
      background: #0068b2;
    }

    .ant-btn-primary:hover {
      border-color: #0068b2df;
      background: #0068b2df;
    }
  }
}

.myRadioGroup {
  background: #0068b2;
  border-radius: 34px 34px 34px 34px;
  padding: 2px;

  .ant-radio-button-wrapper:not(:first-child):before {
    display: none;
  }

  .ant-radio-button-wrapper {
    background: #0068b2;
    border-radius: 34px 34px 34px 34px;
    border: 1px solid #0068b2;
    color: #fff;
    height: 30px;
    line-height: 30px;
    min-width: 80px;
    text-align: center;
  }

  .ant-radio-button-wrapper-checked {
    border: 1px solid #fff !important;
    background: #fff !important;
    color: #0068b2 !important;
    box-shadow: none !important;
  }
}

// #loading {
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background: rgba(0, 0, 0, 0.75);
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   z-index: 9999;
//   font-size: 20px;
// }

.cardItem {
  width: 100%;
  @include flex;
  align-items: center;

  border-radius: 10px;
  background: #fff;
  margin-bottom: 8px;
  padding: 5px 0 !important;

  .leftItem {
    margin-left: 24px;
  }

  .imgItem {
    margin-left: 15px;
    margin-right: 18px;
  }

  .rightItem {
    width: 90%;

    .title {
      font-size: 16px;
      font-weight: 600;
      color: #313131;
      cursor: pointer;

      &:hover {
        color: #0068b2;
      }
    }

    .webContent {
      margin-bottom: 5px;
      font-size: 12px;
      color: #535353;

      .web {
        margin-left: 16px;
      }
    }

    .detailContent {
      z-index: 99;
      position: relative;
      margin-bottom: 5px;
      font-size: 12px;

      .label {
        vertical-align: middle;
        display: inline-block;
        width: 60px;
        margin-right: 8px;
        color: #999999;
      }

      .sharesTip {
        .ant-tooltip-inner {
          font-size: 12px;
          border-radius: 4px;
          user-select: none;
        }
      }

      .value {
        vertical-align: middle;
        display: inline-block;
        width: 130px;
        margin-right: 10px;
        color: #313131;
        // 文字一行省略号显示
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .tagItem {
      .ant-tag {
        border-radius: 15px;
      }
    }
  }

  .iconItem {
    @include flex;
    margin-right: 20px;
    color: #0068b2;
    font-size: 18px;
  }
}
//  .ant-checkbox-wrapper {
//    margin: -3px !important;
//  }

tr.ant-table-measure-row {
  visibility: collapse;
}

// 技术文档api接口页面的样式
.apiReference {
  .highlight {
    color: red;
  }
  /* 限制最多显示 4 行，超出的部分使用省略号 */
  .example-value {
    display: -webkit-box;
    -webkit-line-clamp: 4; /* 限制显示4行 */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  width: 100%;
  padding: 0 30px 15px 30px;
  .contentValue {
    margin-top: 20px;
    .BasicInfo {
      .basicItem {
        margin-left: -10px;
        li {
          font-size: 14px;
          line-height: 30px;
          display: flex;
          > div {
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: #000000d9;
            margin-right: 8px;
            margin-top: 13px;
          }

          > span {
            display: block;
          }
          .child {
            color: #797979;
            white-space: nowrap;
          }
        }
      }
    }
    .requestParam {
      .headers {
        margin-left: 25px;
        margin-bottom: 15px;
        // color: #fe545f;

        font-size: 14px;
      }
      .requestName {
        font-size: 16px;
        margin-left: 25px;
        margin-bottom: 15px;
      }
    }
    .exampleInfo {
      .exampleItem {
        margin-left: 30px;
        font-size: 14px;

        .value {
          margin: 10px 0;
          padding: 10px;
          border-radius: 10px;
          background-color: rgb(245, 245, 245);
        }
      }
    }

    .headerTitleContent {
      margin: 20px 0;
      .title {
        font-weight: normal;
        font-size: 18px;
      }
    }
    .ant-table {
      margin-left: 25px;
    }
    .ant-table-thead {
      border-radius: 10px !important;
    }

    .ant-table-thead > tr > th {
      font-weight: normal;
      font-size: 12px;
      // padding: 12px !important;
      text-align: left !important;
    }

    .ant-table-tbody > tr > td {
      // padding: 12px !important;
      font-size: 12px;
    }

    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(
        .ant-table-row-expand-icon-cell
      ):not([colspan]):before {
      background-color: transparent;
    }
  }
  .ant-input {
    background-color: rgb(245, 245, 245);
    color: #535353;
    border-radius: 10px;
  }
  // textarea,
  // textarea.ant-input:hover,
  // textarea:focus {
  //   border: 1px solid rgb(245, 245, 245);
  //   -webkit-box-shadow: none;
  //   box-shadow: none;
  // }
}
