$mainPadding: 20px;

@mixin flex {
  display: flex;
}

@mixin flex-c-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-a-c {
  align-items: center;
}

@mixin flex-j-c {
  justify-content: center;
}

@mixin flex-j-s-b {
  justify-content: space-between;
}

@mixin flex-a-s-b {
  align-content: space-between;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #eee;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background-color: #a8a8a8;
    }

    &:active {
      background-color: #787878;
    }
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #eee;
  }
}

.FieldTable {
  margin-bottom: 15px;

  .ant-table-body {
    @include scrollbar;
  }

  .ant-table-container {
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
  }
  .ant-table-thead > tr > th {
    padding: 8px !important;
    font-weight: normal;
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    color: #535353;
    background: rgba(0, 104, 178, 0.05) !important;
    border-bottom: 1px solid rgba(0, 104, 178, 0.1);
  }

  .ant-table-tbody > tr > td {
    padding: 8px !important;
    font-size: 12px;
    font-family: PingFang SC-中等, PingFang SC;
    color: #535353;
    border-bottom: 1px solid rgba(0, 104, 178, 0.1);
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan]):before {
    background-color: transparent;
  }

  .ant-table-cell:hover {
    background: rgba(0, 104, 178, 0.05) !important;
    cursor: pointer;
  }

  .ant-table-cell-row-hover {
    background: rgba(0, 104, 178, 0.05) !important;
    cursor: pointer;
  }

  .ant-select-selection-overflow {
    width: 220px;
    flex-wrap: nowrap;
    // 文字一行省略号显示
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .columsInfo {
    .ant-table-tbody > tr > td {
      padding: 12px !important;
    }

    .notCur {
      td {
        cursor: default;
      }
    }
  }
  .iconStyle {
    font-size: 18px;
    color: #006ab2;
    margin-top: 5px;
    margin-right: 5px;
  }
  .insideIconStyle {
    font-size: 18px;
    color: #006ab2;

    margin-right: 5px;
  }
}
