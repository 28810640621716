$mainPadding: 20px;

@mixin flex {
  display: flex;
}

@mixin flex-c-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-a-c {
  align-items: center;
}

@mixin flex-j-c {
  justify-content: center;
}

@mixin flex-j-s-b {
  justify-content: space-between;
}

@mixin flex-a-s-b {
  align-content: space-between;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #eee;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background-color: #a8a8a8;
    }

    &:active {
      background-color: #787878;
    }
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #eee;
  }
}

.DataStandardCheckTable {
  height: 100%;
  width: 100%;
  padding-top: 10px;

  .mySpinLoading {
    height: 750px;
  }

  .tableContent {
    height: calc(100% - 45px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .notCur {
      td {
        cursor: default;
      }
    }

    .ant-table-cell {
      position: relative;
    }

    .iconBox {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }

    .paginationBox {
      position: absolute;
      bottom: 15px;
      right: 20px;

      .myPagination {
        .ant-pagination-item {
          a:hover {
            color: #0068b2;
          }
        }
        .ant-pagination-item-active {
          a {
            color: #0068b2;
            background: rgba(0, 104, 178, 0.09803921729326248);
          }
        }
      }
    }
  }

  .myFormTable .ant-table-thead > tr > th {
    border: none !important;
    font-size: 10px;
    text-align: start;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan]):before {
    background-color: transparent;
  }

  .operation {
    @include flex;
    flex-wrap: nowrap;
    .add {
      margin-right: 16px;
    }
    .modify {
      margin-right: 16px;
    }
  }
  .statu {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #fe545f;
  }
  .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
    margin: -16px -16px -16px 32px;
  }
  .ant-spin-nested-loading > div > .ant-spin {
    background-color: #fff;
  }
}
