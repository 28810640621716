$mainPadding: 20px;

@mixin flex {
  display: flex;
}

@mixin flex-c-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-a-c {
  align-items: center;
}

@mixin flex-j-c {
  justify-content: center;
}

@mixin flex-j-s-b {
  justify-content: space-between;
}

@mixin flex-a-s-b {
  align-content: space-between;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #eee;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background-color: #a8a8a8;
    }

    &:active {
      background-color: #787878;
    }
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #eee;
  }
}

.EleStandardDrawer {
  width: 100%;

  .ant-spin {
    height: 500px !important;
  }
  .ant-modal-content {
    border-radius: 10px;
    height: 700px !important;

    .ant-modal-header {
      padding: 10px 15px;
      border-radius: 10px 10px 0 0;

      .ant-modal-title {
        font-size: 16px;
        font-weight: 400;
        color: #313131;
      }
    }

    .ant-modal-close-x {
      width: 43px;
      height: 43px;
      line-height: 43px;
    }

    .ant-modal-body {
      padding: 15px 0;
    }
  }

  .addModalContent {
    width: 100%;
    height: 100%;
    @include flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 20px;

    .headerTitleContent {
      margin-bottom: 10px;
      .right {
        margin-left: 50px;
      }
    }

    .tableContent {
      width: 100%;

      .tableContent .ant-table-tbody > tr > td {
        padding: 0 !important;
      }
      .myFormTable .ant-table-thead > tr > th {
        border: none !important;
        font-size: 10px;
        text-align: start;
      }
      .ant-table-thead
        > tr
        > th:not(:last-child):not(.ant-table-selection-column):not(
          .ant-table-row-expand-icon-cell
        ):not([colspan]):before {
        background-color: transparent;
      }

      .ant-spin-nested-loading > div > .ant-spin {
        background-color: #fff;
      }
    }

    .btnBox {
      position: absolute;
      left: 520px;
      bottom: 20px;
      flex-direction: row;
      margin: 40px 0 10px 0;
      width: 100%;

      .ant-btn {
        margin-left: 0;

        &:first-of-type {
          margin-right: 20px;
        }

        &:hover {
          border-color: #0068b2;
        }
      }
    }
  }
}
