$mainPadding: 20px;

@mixin flex {
  display: flex;
}

@mixin flex-c-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-a-c {
  align-items: center;
}

@mixin flex-j-c {
  justify-content: center;
}

@mixin flex-j-s-b {
  justify-content: space-between;
}

@mixin flex-a-s-b {
  align-content: space-between;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #eee;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background-color: #a8a8a8;
    }

    &:active {
      background-color: #787878;
    }
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #eee;
  }
}

.GovDetailDrawer {
  .titleVenson {
    margin-right: 8px;
    color: #313131;
    font-size: 14px;
  }
  .states {
    margin-right: 240px;
    @include flex;

    .metaState {
      margin-left: 15px;
      padding: 2px 5px;
      text-align: center;
      font-size: 10px;
      background-color: #61d4ad;
      color: #fff;
    }
  }

  .ant-drawer-content {
    border-radius: 10px 0px 0px 10px;
  }

  .ant-drawer-body {
    padding: 0px;
  }

  .detailsContent {
    padding: $mainPadding;
    width: 100%;

    .detailsBox {
      width: 100%;
      @include flex;
      flex-wrap: wrap;
      margin-bottom: 20px;

      .managementInfo {
        @include flex;
        width: 50% !important;
        justify-content: space-between;
        .detailValue {
          width: 180px !important;
        }
      }

      .detailItem {
        @include flex;
        align-items: center;
        width: 100%;
        margin-top: 10px;

        .detailKey {
          width: 75px;
          line-height: 21px;
          margin-right: 12px;
          font-size: 12px;
          font-weight: normal;
          color: #999999;
          display: inline-block;
          text-align: justify;
          text-align-last: right;
        }

        .detailValue {
          width: 430px;
          font-size: 14px;
          color: #313131;
          .btn {
            @include flex;
            align-items: center;
            justify-content: center;
            background-color: #006ab2;
            color: #fff;
            font-size: 12px;
          }
          .relationshipDetail {
            .sourceIcon {
              width: 16px;
              height: 18px;
              margin-right: 8px;
              display: inline-block;
              vertical-align: middle;
            }
          }
        }
      }
    }
    .apisBox {
      width: 100%;
      margin: 10px 0 20px 0;
      .tableInfo {
        @include flex;
        align-items: center;
        margin-left: 10px;
        margin-bottom: 10px;
        .tableIcon {
          margin-right: 10px;
          margin-top: 7px;
        }
        .typeValue {
          padding: 3px 6px;
          font-size: 12px;
          background-color: #fec054;
          color: #fff;
        }
        .nameValue {
          cursor: pointer;
          font-size: 14px;
          margin-right: 30px;
          &:hover {
            color: #006ab2;
          }
        }
      }
    }
  }

  .mySpinLoading {
    height: 800px !important;
  }
}
