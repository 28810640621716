$mainPadding: 20px;

@mixin flex {
  display: flex;
}

@mixin flex-c-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-a-c {
  align-items: center;
}

@mixin flex-j-c {
  justify-content: center;
}

@mixin flex-j-s-b {
  justify-content: space-between;
}

@mixin flex-a-s-b {
  align-content: space-between;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #eee;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background-color: #a8a8a8;
    }

    &:active {
      background-color: #787878;
    }
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #eee;
  }
}

.dataStandDetailDrawer {
  .titleVenson {
    margin-right: 8px;
    color: #313131;
    font-size: 14px;
  }
  .metaState {
    margin-right: 265px;
    width: 36px;
    height: 18px;
    text-align: center;
    font-size: 10px;
    background-color: #61d4ad;
    color: #fff;
  }
  .ant-drawer-content {
    border-radius: 10px 0px 0px 10px;
  }

  .ant-drawer-body {
    padding: 0px;
  }

  .detailsContent {
    padding: $mainPadding;
    width: 100%;

    .detailsBox {
      width: 100%;
      @include flex;
      flex-wrap: wrap;
      margin-bottom: 20px;

      .managementInfo {
        @include flex;
        width: 50% !important;
        justify-content: space-between;
        .detailValue {
          width: 180px !important;
        }
      }

      .detailItem {
        @include flex;
        align-items: center;
        width: 100%;
        margin-top: 10px;
        padding-left: 12px;

        .detailKey {
          width: 80px;
          line-height: 21px;
          margin-right: 12px;
          font-size: 12px;
          font-weight: normal;
          color: #999999;
          display: inline-block;
          text-align: justify;
          text-align-last: right;
        }

        .detailValue {
          span {
            color: #fe545f;
          }
          width: 400px;
          font-size: 14px;
          color: #313131;
          .btn {
            @include flex;
            align-items: center;
            justify-content: center;
            background-color: #006ab2;
            color: #fff;
            font-size: 12px;
          }
          .relationshipDetail {
            .sourceIcon {
              width: 16px;
              height: 18px;
              margin-right: 8px;
              display: inline-block;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }

  .ant-tag {
    border-radius: 10px;
  }

  .mySpinLoading {
    height: 800px !important;
  }
  .ant-tag {
    padding: 0 4px;
    height: 22px;
    border-radius: 14px;
    text-align: center;
    background-color: #fff;
    &:nth-child(4n + 1) {
      color: #61d4ad;
      border-color: #61d4ad;
    }
    &:nth-child(4n + 2) {
      color: #aad461;
      border-color: #aad461;
    }
    &:nth-child(4n + 3) {
      color: #fec054;
      border-color: #fec054;
    }
    &:nth-child(4n) {
      color: #54b7fe;
      border-color: #54b7fe;
    }
  }
  .dataStandardDetailTable {
    margin-top: 10px;
    margin-bottom: 10px;

    .paginationBox {
      margin-top: 30px;
      display: flex;
      flex-direction: row-reverse;

      .myPagination {
        .ant-pagination-item {
          a:hover {
            color: #0068b2;
          }
        }
        .ant-pagination-item-active {
          a {
            color: #0068b2;
            background: rgba(0, 104, 178, 0.09803921729326248);
          }
        }
      }
    }

    .ant-table-thead {
      border-radius: 10px !important;
    }

    .ant-table-thead > tr > th {
      padding: 8px !important;
      font-weight: normal;
      font-size: 12px;
      border: none !important;
      text-align: left !important;
    }

    .ant-table-tbody > tr > td {
      padding: 16px !important;
      font-size: 12px;
    }

    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(
        .ant-table-row-expand-icon-cell
      ):not([colspan]):before {
      background-color: transparent;
    }

    .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
      margin: -16px -16px -16px 35px;
    }
    .ant-spin-nested-loading > div > .ant-spin {
      background-color: #fff;
    }

    .detailsBoxNew {
      margin-left: 7px;
      margin-bottom: 8px;
      @include flex;
      width: 100%;
      justify-content: space-between;

      .detailItems {
        @include flex;
        width: 50%;

        .Key {
          margin-right: 10px;
        }
      }
    }
  }
}
