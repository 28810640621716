$mainPadding: 20px;

@mixin flex {
  display: flex;
}

@mixin flex-c-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-a-c {
  align-items: center;
}

@mixin flex-j-c {
  justify-content: center;
}

@mixin flex-j-s-b {
  justify-content: space-between;
}

@mixin flex-a-s-b {
  align-content: space-between;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #eee;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background-color: #a8a8a8;
    }

    &:active {
      background-color: #787878;
    }
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #eee;
  }
}

.AddModal {
  width: 100%;

  .ant-tooltip {
    left: 290px !important;
  }

  .modalForm {
    @include flex;

    .ant-form-item {
      margin-right: 20px;
    }
  }

  .ant-modal-content {
    border-radius: 10px;
    height: 800px !important;

    .ant-modal-header {
      padding: 10px 15px;
      border-radius: 10px 10px 0 0;

      .ant-modal-title {
        font-size: 16px;
        font-weight: 400;
        color: #313131;
      }
    }

    .ant-modal-close-x {
      width: 43px;
      height: 43px;
      line-height: 43px;
    }

    .ant-modal-body {
      padding: 20px 0;
    }
  }

  .addModalContent {
    @include flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 20px;
    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(
        .ant-table-row-expand-icon-cell
      ):not([colspan]):before {
      background-color: transparent;
    }

    .tableContent {
      .ant-empty {
        line-height: 350px;
      }
      .ant-table-thead > tr > th {
        padding: 10px;
        text-align: start;
      }
      .ant-table-tbody > tr > td {
        padding: 10px;
      }

      .myPaginationSite {
        position: absolute;
        bottom: 80px;
        right: 25px;
      }

      .paginationBox {
        @include flex;
        flex-direction: row-reverse;
        margin-top: 15px;

        .myPagination {
          .ant-pagination-item {
            a:hover {
              color: #0068b2;
            }
          }
          .ant-pagination-item-active {
            a {
              color: #0068b2;
              background: rgba(0, 104, 178, 0.09803921729326248);
            }
          }
        }
      }
    }

    .btnBox {
      // @include flex-c-c;
      position: absolute;
      left: 398px;
      bottom: 20px;
      flex-direction: row;
      margin: 40px 0 10px 0;
      width: 100%;

      .ant-btn {
        margin-left: 0;

        &:first-of-type {
          margin-right: 20px;
        }

        &:hover {
          border-color: #0068b2;
        }
      }
    }
  }
  .ant-input-affix-wrapper {
    border-radius: none;
  }

  .titleValue {
    &:hover {
      color: #0068b2;
      cursor: pointer;
    }
  }

  .table-tr-row-id {
    background: rgba(0, 104, 178, 0.05) !important;
  }
}

.AddModal .headerTipContent {
  .headerTip {
    @include flex;
    flex-wrap: wrap;

    .headerTipTitle {
      width: 100%;
      font-weight: 500;
      font-size: 14px;
    }

    .headerTipText {
      width: 50%;
      font-size: 12px;
      font-weight: 0;
    }
  }
}
