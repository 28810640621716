$mainPadding: 20px;

@mixin flex {
  display: flex;
}

@mixin flex-c-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-a-c {
  align-items: center;
}

@mixin flex-j-c {
  justify-content: center;
}

@mixin flex-j-s-b {
  justify-content: space-between;
}

@mixin flex-a-s-b {
  align-content: space-between;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #eee;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background-color: #a8a8a8;
    }

    &:active {
      background-color: #787878;
    }
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #eee;
  }
}

.ModelApplyDrawer {
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: #fff;
  .mySpinLoading {
    height: 900px;
  }

  .selectBtn {
    border-radius: 38px;
    background-color: #d4d4d4;

    .ant-btn {
      border-radius: 38px;
      color: #fff;
      font-size: 12px !important;
    }
  }

  .dialogDrawerForm {
    width: 100%;
    padding: 15px 32px;
    @include flex;
    flex-wrap: wrap;
    padding-bottom: 0;

    .headerTitleContent {
      width: 100%;
      font-size: 14px;
      margin-bottom: 8px;
      margin-left: -13px;
    }

    .ant-form-item {
      margin-right: 40px;
    }

    .drawerFooterBtnBox {
      width: 100%;
    }

    .modalApply {
      width: 100%;
      @include flex;
      flex-wrap: nowrap;
      .modalApplyItem {
        @include flex;
        flex-direction: column;
        margin-bottom: 10px;

        .modalValue {
          width: 424px;
          border-radius: 10px;

          .tableInfo {
            @include flex;
            align-items: center;
            font-size: 12px;
            margin-bottom: 12px;
            .typeValue {
              padding: 3px 8px;
              margin-right: 12px;
              background-color: #fec054;
              color: #fff;
            }
            .nameValue {
              .ant-tooltip {
                min-width: none;
              }
              width: 294px;
              // 文字一行省略号显示
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              cursor: pointer;
              font-size: 14px;
              margin-right: 10px;
              &:hover {
                color: #006ab2;
              }
            }
          }
          .ant-input {
            border-radius: 10px;
          }
        }
      }
    }
  }

  .drawerFooterBtnBox {
    margin-right: 40px;
    margin-bottom: 10px;
  }
  .ant-input[disabled] {
    background-color: #fff;
    color: #535353;
  }
}
