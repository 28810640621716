$mainPadding: 20px;

@mixin flex {
  display: flex;
}

@mixin flex-c-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-a-c {
  align-items: center;
}

@mixin flex-j-c {
  justify-content: center;
}

@mixin flex-j-s-b {
  justify-content: space-between;
}

@mixin flex-a-s-b {
  align-content: space-between;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #eee;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background-color: #a8a8a8;
    }

    &:active {
      background-color: #787878;
    }
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #eee;
  }
}

.BloodRelation {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 16px 18px;
  background: #ffffff;
  @include flex;

  .addModalContent {
    width: 100%;
    height: 100%;
    @include flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .blood {
      color: #313131;
      height: 28px;
      border-bottom: 1px solid #f0f0f0;
      margin-bottom: 10px;
    }

    .tableContent {
      width: 100%;
      height: calc(100% - 60px);
      overflow: hidden;
    }

    .UpstreamAndDownstream {
      width: 100%;
      height: 30px;
      @include flex;
      justify-content: space-between;
      padding: 0 25px;
      .Icon {
        display: inline-block;
        vertical-align: -10%;
        margin-right: 15px;
        width: 10px;
        height: 10px;
      }
      .ant-btn {
        &:hover {
          border-color: #ccc;
        }
      }
    }

    .btnBox {
      text-align: center;
      width: 100%;
      height: 30px;

      .ant-btn {
        margin-right: 20px;

        &:hover {
          border-color: #0068b2;
        }
      }
    }
  }
}
