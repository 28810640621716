$mainPadding: 20px;

@mixin flex {
  display: flex;
}

@mixin flex-c-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-a-c {
  align-items: center;
}

@mixin flex-j-c {
  justify-content: center;
}

@mixin flex-j-s-b {
  justify-content: space-between;
}

@mixin flex-a-s-b {
  align-content: space-between;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #eee;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background-color: #a8a8a8;
    }

    &:active {
      background-color: #787878;
    }
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #eee;
  }
}

.messageModal {
  .rejectInfo {
    padding: 0 24px;
  }
  .ant-modal-content {
    border-radius: 10px;

    .ant-modal-header {
      padding: 10px 15px;
      border-radius: 10px 10px 0 0;

      .ant-modal-title {
        font-size: 16px;
        font-weight: 400;
        color: #313131;
      }
    }

    .ant-modal-close-x {
      width: 43px;
      height: 43px;
      line-height: 43px;
    }

    .ant-modal-body {
      padding: 20px 0;
    }
  }

  .messageModalContent {
    @include flex-c-c;
    flex-direction: column;
    max-height: 100vh;
    overflow-y: auto;
    @include scrollbar;
    padding: 0 20px;

    .iconBox {
      font-size: 34px;
      color: red;
    }
    .founder {
      display: flex;
      align-items: center;
      justify-content: center;
      .name {
        margin-right: 10px;
      }
    }

    .btnBox {
      @include flex-c-c;
      flex-direction: row;
      margin: 20px 0 10px 0;
      width: 100%;

      .ant-btn {
        margin-left: 0;

        &:first-of-type {
          margin-right: 20px;
        }

        &:hover {
          border-color: #0068b2;
        }
      }
    }
  }
  .DataCheckDetailDrawer {
    height: 88vh;
    padding: 10px 20px;
    background: #ffffff;
    border-radius: 10px 10px 10px 10px;
    width: 100%;
    .ant-spin {
      height: 770px !important;
      max-height: 770px !important;
    }

    .selectTime {
      margin-top: -20px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      .name {
        margin-right: 10px;
      }
      .compareType {
        margin: 0 10px;
      }
    }
    .allTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 18px;
      .title {
        display: flex;
        align-items: center;
        > div {
          margin-right: 5px;
        }
        .blue {
          color: rgb(1, 109, 180);
        }
        .mr10 {
          margin-right: 15px;
        }
      }
    }

    .dataTaskTable {
      margin-top: 10px;
      height: 90%;
    }
    .ant-table-thead > tr > th {
      color: #535353;
      font-weight: normal;
      font-size: 12px;
      text-align: left !important;
      background: #e1eeff !important;
    }

    .ant-table-cell:hover {
      background: #e1eeff !important;
      cursor: pointer;
      // user-select: none;
    }

    .ant-table-cell-row-hover {
      background: #e1eeff !important;
      cursor: pointer;
      // user-select: none;
    }
    .ant-table-tbody > tr > td,
    .ant-table-thead > tr > th {
      padding: 16px;
    }
    .ant-table-tbody > tr > td {
      padding: 8px 19px !important;
      font-size: 12px;
      color: #535353;
      border-bottom: 1px solid #d1d0d0;
    }
    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(
        .ant-table-row-expand-icon-cell
      ):not([colspan]):before {
      background-color: transparent;
    }

    .operation {
      @include flex;
      flex-wrap: nowrap;

      .derive {
        margin-left: 16px;
      }
    }
    .statu {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #fe545f;
    }

    .ant-spin-nested-loading > div > .ant-spin {
      background-color: #fff;
    }

    .paginationBox {
      position: absolute;
      bottom: 10px;
      right: 20px;

      .myPagination {
        .ant-pagination-item {
          a:hover {
            color: #0068b2;
          }
        }
        .ant-pagination-item-active {
          a {
            color: #0068b2;
            background: rgba(0, 104, 178, 0.09803921729326248);
          }
        }
      }
    }
    .ant-table-cell {
      .ant-row {
        margin: 0 0 10px 0 !important;
      }
    }
  }
}
