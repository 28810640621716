$mainPadding: 20px;

@mixin flex {
  display: flex;
}

@mixin flex-c-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-a-c {
  align-items: center;
}

@mixin flex-j-c {
  justify-content: center;
}

@mixin flex-j-s-b {
  justify-content: space-between;
}

@mixin flex-a-s-b {
  align-content: space-between;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #eee;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background-color: #a8a8a8;
    }

    &:active {
      background-color: #787878;
    }
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #eee;
  }
}

.PackageGovContent {
  width: 100%;
  height: 100%;
  @include flex;
  flex-direction: column;
  align-items: flex-end;

  .topBox {
    width: 100%;
    height: 70px;
    background: #ffffff;
    border-radius: 10px;
    padding: 18px 18px 18px 18px;
    @include flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;

    .searchItem {
      @include flex;
      align-items: center;
      height: 29px;
      font-size: 14px;
      margin-bottom: 10px;

      .label {
        width: 90px;
        flex-basis: 80px;
        white-space: nowrap;
        margin-right: 20px;
        font-weight: normal;
        color: #999999;
        display: inline-block;
        text-align-last: right;
      }

      .optionsBox {
        @include flex;

        .subvalue {
          @include flex;
          align-items: center;
          color: #006ab2;
        }

        .optionItem {
          flex-basis: 18px;
          flex-shrink: 0;
          white-space: nowrap;
          height: 24px;
          line-height: 24px;
          padding: 0 7px;
          margin-right: 12px;
          text-align: center;
          cursor: pointer;
        }

        .borderBottom {
          width: 200px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.08);
          padding: 0px;
        }
      }
    }

    .buttonBox {
      .ant-btn {
        height: 28px;
        vertical-align: middle;
        margin-left: 10px;
        font-size: 12px !important;
      }
    }
  }

  .filterItem {
    width: 98.4%;
    margin-left: 24px;
    height: 38px;
    line-height: 38px;
    font-size: 14px;
    @include flex;
    user-select: none;
    justify-content: space-between;

    .ant-checkbox + span {
      font-size: 14px;
      color: #535353;
      padding-right: 20px;
    }
    .optionBoxs {
      @include flex;
      .optionItem {
        @include flex;
        align-items: center;
        margin-right: 30px;
        color: #535353;
        font-size: 14px;
        font-weight: normal;
        .iconBox {
          @include flex;
          margin-left: 5px;
          flex-direction: column;
          color: #787878;
          .upIcon {
            &:hover {
              color: #0068b2;
            }
          }
          .downIcon {
            margin-top: -5px;
            &:hover {
              color: #0068b2;
            }
          }
        }
      }
    }

    .buttonBox {
      margin-top: -2px;
      .ant-btn {
        height: 27px;
        vertical-align: middle;
        margin-left: 10px;
        font-size: 12px !important;
      }
    }
  }

  .cardBox {
    width: 100%;
    height: 80%;
    overflow-y: auto;
    margin-bottom: 15px;

    .noData {
      width: 100%;
      height: 660px;
      @include flex-c-c;
    }

    .cardItem {
      .ant-checkbox-input {
        &::after {
          position: absolute;
          content: "";
          top: -10px;
          bottom: -40px;
          left: -20px;
          right: -1100px;
        }
      }
      .title {
        .stateCode {
          margin-left: 15px;
          display: inline-block;
          text-align: center;
          width: 36px;
          height: 18px;
          background-color: #fec054;
          color: #fff;
          font-size: 10px;
        }
      }

      .value {
        width: 60px;
      }
    }
  }
  .ant-spin {
    margin-top: 320px;
  }
  .paginationBox {
    height: 30px;
  }

  .ant-checkbox-wrapper {
    margin-bottom: 0;
  }

  .ant-btn-primary[disabled] {
    background-color: rgb(222, 222, 222) !important;
    border-color: #dedede !important;
    color: #fff !important;
  }
}
