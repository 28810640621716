$mainPadding: 20px;

@mixin flex {
  display: flex;
}

@mixin flex-c-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-a-c {
  align-items: center;
}

@mixin flex-j-c {
  justify-content: center;
}

@mixin flex-j-s-b {
  justify-content: space-between;
}

@mixin flex-a-s-b {
  align-content: space-between;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #eee;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background-color: #a8a8a8;
    }

    &:active {
      background-color: #787878;
    }
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #eee;
  }
}

.ModelStandardTable {
    height: 100%;
    width: 100%;

    .topValue{
        @include flex;
        margin-top: 20px;
        margin-bottom: 20px;
        justify-content: space-between;
        .ant-btn {
            width: 96px;
            @include flex;
            @include flex-a-c;
            justify-content: center;
            font-size: 12px;
            vertical-align: middle;
            .Icon {
                margin-right: 6px;
                margin-top: 4px;
            }
          }
    }

      .tableContent {
        .myFormTable .ant-table-thead>tr>th {
                border: none !important;
                padding: 13px !important;
                font-size: 10px;
                text-align: start;
            }

        .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before{
           background-color: transparent;
           }

        .myFormTable .ant-table-tbody>tr>td {
          font-size: 12px ;
          padding: 15px;
        }
        }
        .drawerFooterBtnBox{
            position: absolute;
            bottom: 24px;
            right: 32px;
           
        }

}
