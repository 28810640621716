$mainPadding: 20px;

@mixin flex {
  display: flex;
}

@mixin flex-c-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-a-c {
  align-items: center;
}

@mixin flex-j-c {
  justify-content: center;
}

@mixin flex-j-s-b {
  justify-content: space-between;
}

@mixin flex-a-s-b {
  align-content: space-between;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #eee;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background-color: #a8a8a8;
    }

    &:active {
      background-color: #787878;
    }
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #eee;
  }
}

.App {
  // text-align: center;
  height: 100%;
  width: 100%;

  .my-layout {
    height: 100%;
    width: 100%;
  }

  .my-content {
    padding: 20px;
    padding-top: 0px;
    background-color: #f7f7f7;
    .contentBox {
      height: 100%;
      width: 100%;
      // background: #fff;
      border-radius: 5px;
    }

    .scrollbars {
      border-radius: 5px;
      // background: #fff;
    }
  }

  .ant-layout-content {
    overflow: auto;
  }

  .breadcrumb {
    padding: 8px 0px;
    font-size: 12px;
    padding-left: 24px;
    color: #000;
    background-color: #f7f7f7;
    user-select: none;
  }
  .wscn-http404-container {
    margin-top: 50px;
    margin-left: 100px;
  }
}
