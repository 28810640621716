$mainPadding: 20px;

@mixin flex {
  display: flex;
}

@mixin flex-c-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-a-c {
  align-items: center;
}

@mixin flex-j-c {
  justify-content: center;
}

@mixin flex-j-s-b {
  justify-content: space-between;
}

@mixin flex-a-s-b {
  align-content: space-between;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #eee;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background-color: #a8a8a8;
    }

    &:active {
      background-color: #787878;
    }
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #eee;
  }
}

.TecDocumentation {
  height: 100%;
  width: 100%;

  .my-layout {
    height: 100%;
    width: 100%;
  }
  .ant-layout-has-sider {
    padding: 20px;
  }

  .my-content {
    margin-left: 20px;
    height: 100%;
    width: 100%;
    padding: 20px;
    background: #fff;
    border-radius: 15px;

    .contentBox {
      padding-bottom: 20px;
      height: 100%;
      width: 100%;
      .contentTitle {
        width: 96%;
        height: 80px;
        margin: 0 30px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
        .breadcrumb {
          @include flex;
          align-items: center;
          height: 30px;
          font-size: 12px;
          color: #000;
        }
        .homeTitle {
          font-size: 28px;
          font-weight: 400;
        }
      }
    }
  }

  .ant-layout-content {
    overflow: auto;
  }
  .mySpinLoading {
    height: 90%;
  }
}
