$mainPadding: 20px;

@mixin flex {
  display: flex;
}

@mixin flex-c-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-a-c {
  align-items: center;
}

@mixin flex-j-c {
  justify-content: center;
}

@mixin flex-j-s-b {
  justify-content: space-between;
}

@mixin flex-a-s-b {
  align-content: space-between;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #eee;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background-color: #a8a8a8;
    }

    &:active {
      background-color: #787878;
    }
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #eee;
  }
}

.overviewDrawer {
  z-index: 100;

  .ant-drawer-content {
    border-radius: 10px 0px 0px 10px;
  }

  .ant-drawer-body {
    padding: 0px;
  }

  .dialogDrawerForm {
    width: 100%;
    padding: 20px 32px;
    @include flex;
    @include flex-j-s-b;
    flex-wrap: wrap;
    padding-bottom: 0;

    .headerTitleContent {
      width: 100%;
      font-size: 14px;
      margin-bottom: 8px;
      margin-left: -13px;
    }
  }
  .ant-row {
    width: 100%;
  }
  .ant-checkbox-group {
    @include flex;
    flex-direction: column;
  }
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }
  .ant-checkbox-input {
    &::after {
      position: absolute;
      content: "";
      top: -5px;
      bottom: -6px;
      left: -20px;
      right: -600px;
    }
  }

  // 分页
  .paginationBox {
    width: 100%;
    text-align: end;
    margin-bottom: 15px;
  }
}
.storeTipContent {
  width: 400px;
  .headerTip {
    @include flex;
    flex-wrap: wrap;

    .headerTipTitle {
      width: 100%;
      font-weight: 500;
      font-size: 14px;
    }

    .headerTipText {
      // width: 50%;
      font-size: 12px;
      font-weight: 0;
    }
  }
}
